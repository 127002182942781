﻿@import '../../../../../Common/src/scss/components/react/CatalogFilters.scss';

.CatalogFilters {
    background-color: $gray-light;

    .CatalogFilters__paging {
        margin-top: 0;
        margin-bottom: 0;

        .FilterDropdown {
            height: 100%;

            button {
                height: 100%;
            }
        }
    }
}
