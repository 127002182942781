﻿@import '../../../../../Common/src/scss/components/react/ProductGallery.scss';

.ProductGallery {
    .btn {

    }
}
.Carousel .CarouselItem {
    font-size: 1rem;
    width: 5em;
    height: 5em;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .MediaConverter,
    img {
        height: 100% !important;
        width: 100% !important;
    }

    @include media-breakpoint-down(sm) {
        font-size: 0.67rem;
    }
}
