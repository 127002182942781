.SparePartsTable {
    display: flex;
    justify-content: center;
    width: 100%;

    @include media-breakpoint-up(lg) {
        display: block;
    }

    .Grid {
        .Row:hover .Grid__cell {
            font-weight: 700;
        }

        &__cell {
            &--header {
                font-size: 1.25rem;
                font-weight: 700;
                text-transform: uppercase;
                color: var(--light);
                padding: 0.5rem 0;
            }

            &:not(.Grid__cell--header) {
                padding: 0.33rem 0;
                border-bottom: 1px solid $gray-medium;

                &:not(.Grid__cell--interrupter) {
                    cursor: pointer;
                }
            }

            &.border-left {
                border-left: 1px solid $gray-medium;
            }

            &.border-right {
                border-right: 1px solid $gray-medium;
            }
        }
    }
}
