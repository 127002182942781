@import '../../../../../Common/src/scss/components/react/CustomerPaymentManager.scss';

.CustomerPaymentManager {
    iframe:not(.gpay-card-info-iframe) {
        border-top: 1px solid $gray-medium !important;
    }

    button[type='submit'] {
        margin-right: 1em;
    }
}
