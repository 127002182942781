.SparePartsPartial {
    margin: 2.5rem 0;

    &__Title {
        margin-left: 3.25rem;

        @include media-breakpoint-down(sm) {
            margin-left: 0;
            text-align: center;
        }
    }

    &__Main {
        margin-top: 1rem;
        display: flex;

        &__Details {
            width: 100%;
            height: min-content;
            display: grid;
            grid-template-columns: min-content minmax(0, 1fr);
            gap: 1rem;
            font-size: 1.5rem;

            .detail-key {
                font-weight: 700;
            }

            @include media-breakpoint-down(sm) {
                gap: 0.75rem;
                font-size: 1.25rem;
            }
        }
    }
}
