﻿@import '../../../../../Common/src/scss/components/react/ProductSpecifications.scss';

@mixin tablecell-bottom-border {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;

    box-sizing: content-box;
    content: ' ';
}

.ProductSpecifications {
    .Table {
        .table {
            thead {
                border-bottom: 2px solid $yellow;

                th:first-child {
                    &::after {
                        @include tablecell-bottom-border();
                        border-bottom: 2px solid $yellow;
                    }
                }

                th {
                    vertical-align: middle !important;
                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid $dark;
                }

                th[scope='row']:first-child {
                    &::after {
                        @include tablecell-bottom-border();
                        border-bottom: 1px solid $dark !important;
                    }
                }
            }

            .subheader {
                font-weight: bold !important;
            }
        }
    }
}
