@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOutUp {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-20px);
    }
}

.SparePartsFinder {
    &__Title {
        margin-top: 2rem;
    }

    &__Title,
    &__Subtitle {
        text-align: center;

        @include media-breakpoint-up(lg) {
            text-align: left;
        }
    }

    &__Dropdowns {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;

        > * {
            max-width: 28%;
            display: flex;
            align-items: stretch;
        }

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            justify-content: stretch;
            align-items: center;

            > * {
                max-width: 100%;
                width: 100%;
                justify-content: stretch;
            }
        }

        .Dropdown {
            &__drawer {
                max-height: 50vh;
                overflow-y: scroll;
                z-index: 10;
                background-color: var(--light);

                .Grid {
                    text-wrap: revert;

                    .btn {
                        text-align: left;
                    }
                }
            }

            &__button.btn {
                height: 100%;
                padding: 0.5rem 2rem;
                border-radius: 0.5rem;
                border-color: var(--primary);
                border-width: 2px;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;

                &__container {
                    width: 100%;
                }

                &__button.btn {
                    width: 100%;
                    justify-content: center;
                }
            }
        }

        &__Reset {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            a {
                color: var(--primary);

                @media (hover: hover) {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &__Main {
        display: flex;
        flex-direction: column;
        min-height: 400px;

        &__Placeholder {
            &-img {
                width: 100%;
            }
        }

        .fade-in {
            animation: fadeInDown 0.5s forwards;
        }

        .fade-out {
            animation: fadeOutUp 0.5s forwards;
        }
    }
}
