.article__card {
    .card-img-top {
        filter: brightness(60%) !important;
    }

    .card-title {
        font-family: var(--primary-font) !important;
        font-weight: 600 !important;
        text-transform: uppercase !important;
        @include font-size(24px, true);
    }

    .card__footer-link {
        @include font-size(14px, true);
        font-weight: 600 !important;
    }

    .subtitle {
        font-weight: 600 !important;
    }
}

.article__cardrow {
    .card {
        .subtitle {
            text-transform: uppercase !important;
        }

        .card-title {
            font-weight: 600 !important;
        }

        .card-img-top {
            filter: brightness(60%) !important;
        }

        .card__footer-link {
            position: initial;
        }
    }
}
