@import '../../../Common/src/scss/components/react/SparePartsPartial.scss';

.SparePartsPartial {
    &__Title {
        text-decoration: underline var(--primary);
    }

    &__Main {
        &__Details {
            .detail-key {
                text-decoration: underline var(--primary);
            }
        }
    }
}
