﻿@import '../../../Common/src/scss/components/_inputs.scss';

input:not([type='checkbox']):not([type='radio']):not([type='submit']),
select,
textarea,
textarea:hover {
    border: 1px solid $gray-medium;

    &::placeholder {
        font-weight: 600;
        @include font-size(16px);
    }
}

.Form__Element textarea {
    width: 100%;
    text-indent: 0.25em;

    &::placeholder {
        line-height: 2em;
        text-indent: 2em;
    }
}

.Form__Element__Caption {
    &:empty {
        position: absolute;
    }
}

.FormSubmitButton {
    background-color: var(--primary);
    border: none;
    padding: 0.5em 2em;
    text-transform: uppercase;
}
