.ProductChildTable {
    tbody {
        td {
            @include font-size(14px);
        }
    }

    .Grid {
        &__cell {
            &--header {
                color: var(--dark);
            }
        }
    }
}
