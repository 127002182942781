﻿@import '../../../../Common/src/scss/components/_navigation_main.scss';

.navigation__main {
    height: 100%;

    .navbar-nav {
        height: 100%;

        .nav-item {
            height: 100%;

            .nav-link {
                @include font-size(14px);
                color: $white;

                .nav-link__content {
                    transition: padding-bottom 0.25s;
                }
            }

            .nav-item__children-panel {
                display: none;
                position: absolute;
                width: 100%;
                top: 100%;
                left: 0;
                background-color: $white;
                border-top: 15px solid $yellow;
                z-index: $zindex-popover;

                .nav-item__children-list {
                    list-style-type: none;
                    padding-left: 0;

                    li {
                        a {
                            font-size: 18px;
                            font-weight: 500;
                            text-transform: uppercase;

                            overflow-wrap: break-word;
                            word-break: break-word;
                            white-space: normal;
                            max-width: 100%;

                            &:hover {
                                text-decoration: none;
                                background-color: $gray-medium;
                            }
                        }
                    }
                }
            }

            &.active,
            &:hover {
                .nav-link {
                    background-color: transparent;

                    &::after {
                        content: ' ';
                        background-color: $yellow;
                        width: 50%;
                        left: 25%;
                        bottom: 8px;
                        position: absolute;
                        height: 1px;
                    }
                }
            }

            &:hover {
                .nav-item__children-panel {
                    display: block;
                }
            }
        }
    }
}

.navbar-toggler {
    color: $white;
    z-index: 1;
}

.navigation__mobile {
    background-color: $black;
    position: fixed;
    width: 100%;
    height: 100%;
    left: -100%;
    top: 0;
    z-index: $zindex-modal;
    transition: left 0.5s;
    overflow-y: auto;

    .navigation__mobile-nav {
        border-bottom: 2px solid $yellow;
    }

    &.show {
        left: 0;
    }

    .header__social {
        text-align: center;
    }

    .navigation__mobile-collapse-button {
        border: none;
        background-color: transparent;
        color: $white;
        @include font-size(36px);
    }

    .navigation__mobile-list {
        list-style-type: none;
        padding-left: 0;

        li {
            a {
                @include font-size(24px);
                text-transform: uppercase;
                color: $yellow;
                font-weight: 500;

                &:hover {
                    text-decoration: none;
                }

                &.has-children:after {
                    float: right;
                    content: '›';
                    margin-right: 0;
                }
            }
        }
    }
}

.navigation__mobile__child {
    z-index: $zindex-modal + 1;

    .navigation__mobile-child-header {
        @include font-size(28px);
    }

    .navigation__mobile-return-home {
        button {
            text-transform: none;
        }
    }

    .menucta__links__mobile img {
        filter: invert(1);
    }
}
