.filter {
    .dropdown-menu {
        background-color: $gray-light !important;
        color: $gray-dark !important;

        input[type='checkbox'],
        input[type='radio'] {
            &.light {
                border-color: $gray-dark !important;
            }

            &::before {
                background-color: $gray-dark !important;
            }
        }
    }
}

.dropdown__drawer {
    background-color: $gray-light;

    a {
        color: $gray-dark;

        &:hover {
            background-color: $gray-dark;
            color: $gray-light;
        }
    }
}
