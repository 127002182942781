﻿@import '../../../../../Common/src/scss/components/react/ProductRecommendations.scss';

.ProductRecommendations {
    &__Product {
        > div {
            @extend .text-center;
        }

        .ProductRating {
            .yotpo-bottomline {
                display: flex !important;
                justify-content: center !important;

                a.text-m {
                    display: none;
                }
            }
        }
    }
}
