﻿@import '../../../../../Common/src/scss/components/react/ProductBadge.scss';

.ProductBadge {
    .badge {
        text-transform: uppercase;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-left: 1em;
        padding-right: 1em;
    }
}
