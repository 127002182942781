@import 'StoreLocator.scss';

.PrestonStoreLocator {
    padding: 50px;
    margin: 0px;

    .btn-yellow {
        background: var(--primary);
        color: #000;
    }

    .btn {
        border-radius: 0px;
        text-decoration: capitalize;
    }

    .btn-blue {
        background: $blue;
        color: #fff;
    }

    ul {
        list-style-type: none;
        padding: 0px;
        margin-bottom: 0px;
    }

    p {
        margin-bottom: 0px;
    }

    .facet_container {
        background-color: #d1d3d4;
        padding: 10px 0px;

        button {
            background-color: transparent;
            border: none;
            padding: 10px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: default;
        }

        li {
            margin: 5px 0px;
        }

        a {
            padding: 8px 12px;
            transition: all 0.2s;
            color: $black;
            width: 100%;
            display: block;
            cursor: pointer;
        }

        @media (hover: hover) {
            a:hover {
                text-decoration: none;
                background-color: var(--primary);
            }
        }

        a.selected {
            text-decoration: none;
            background-color: var(--primary);
        }

        h6 {
            margin-bottom: 0px;
        }

        i,
        h6 {
            display: inline;
        }
    }

    .divider {
        margin-top: 20px;
        margin-bottom: 40px;
        height: 1px;
        background-color: $black;
        width: 100%;
    }

    .locations_container {
        li {
            border-top: 1px solid $gray-medium;
            padding: 20px 0px;
        }

        li:last-child {
            border-bottom: 1px solid $gray-medium;
        }

        .inner {
            a {
                font-size: 16px;
            }
            button {
                margin: 0px 10px;
            }
        }

        .locations {
            .location > .preview {
                h6 {
                    font-weight: 500;
                    margin-bottom: 0px;
                }

                p {
                    margin-bottom: 0px;
                }
            }

            .location > .dropdown {
                p,
                tr > th,
                tr > td {
                    @extend .paragraph-2;
                }

                p,
                tr > th {
                    font-weight: 500;
                }

                .disclaimer {
                    margin-top: 25px;
                    padding: 25px;
                    background: $gray-medium;
                    color: $black;
                }

                .table_container {
                    overflow-y: auto;
                    margin: 25px 0px;

                    .table {
                        width: fit-content;

                        th {
                            font-weight: 500;

                            &[scope='col'] {
                                border-top: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .pagination {
        display: flex;
        justify-content: center;
        margin-top: 25px;
        width: 100%;

        .ellipse {
            display: flex;
            align-items: flex-end;
            padding: 5px;
        }

        .page {
            padding: 5px;
            border-bottom: 2px solid transparent;

            @media (hover: hover) {
                &:hover {
                    cursor: pointer;
                    border-color: var(--primary);
                    transition: border-color 0.2s;
                }
            }

            &.selected {
                border-color: var(--primary);
                transition: border-color 0.2s;
            }

            &.arrow {
                font-size: 1rem;
                display: flex;
                align-items: center;
                @media (hover: hover) {
                    &:hover {
                        border-color: transparent;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .facet_container button {
            cursor: pointer;
        }

        .locations_container {
            .inner {
                float: right;
                display: flex;
                flex-direction: column;
                justify-content: center;
                button,
                a {
                    display: block;
                }

                button {
                    margin: 5px 0px;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        padding: 25px;

        .locations_container {
            margin-top: 25px;

            .inner {
                float: left;
                display: block;
                text-align: left;
                margin-top: 15px;
            }

            .disclaimer {
                padding: 10px;
            }
        }
    }
}
