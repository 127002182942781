.cardcarouselblock {
    .carousel__header--style1 {
        margin-top: 1em;
    }

    .carousel__header--style2 {
        > h3 {
            &::after {
                display: none;
            }
        }

        .carousel__header-subtitle {
            a {
                font-weight: 600;

                text-decoration: none;

                @media (hover: hover) {
                    &:hover {
                        border-bottom-width: 1px;
                        border-bottom-style: solid;
                    }
                }

                &::after {
                    margin-left: 0.5em;
                    content: '›';
                    @include font-size(32px);
                }
            }
        }
    }

    .carousel__card {
        &.carousel__card--style2 {
            min-height: 160px !important;

            .carousel__card-background {
                filter: brightness(50%);
            }

            .title {
                @include font-size(18px, true);
                font-weight: 500;
            }

            .red-devider {
                margin: 5px auto;
                height: 1px;
                width: 6rem;
            }

            .card-front-text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-top: 0;
            }
        }

        &.carousel__card--style6 {
            background-color: transparent;

            .btn {
                padding: 0 !important;
                text-align: left;
                @include font-size(12px);
                font-weight: 600 !important;
            }
        }
    }
}
