﻿@import '../../../../../Common/src/scss/components/react/ProductInput.scss';

.ProductInput {
    input {
        width: 3em;
    }

    button {
        height: 100%;
        padding-top: 0 !important;
    }
}
