﻿@import '../../../../../Common/src/scss/components/react/ProductTabContent.scss';

$underline-width: 70%;

.ProductTabContent {
    .tab-content {
        margin-top: 27px;
    }

    .nav-tabs {
        .nav-item {
            .nav-link {
                font-weight: bold;
                position: relative;

                &.active {
                    color: $dark;

                    &::after {
                        position: absolute;
                        bottom: 0;
                        background-color: $yellow;
                        left: (100% - $underline-width) * .5;
                        width: $underline-width;
                        height: 3px;
                        content: ' ';
                    }
                }
            }
        }
    }
}
