@import '../../../Common/src/scss/blocks/_newslettersubscriptionblock.scss';

.newsletter__subscription {
    background-color: $dark;
    margin-left: -1rem;
    margin-right: -1rem;

    .subscription {
        &__form {
            @include media-breakpoint-down(md) {
                padding-right: 20px;
                padding-left: 20px;
            }
        }
    }

    button[type='submit'] {
        max-width: 220px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;

        @include media-breakpoint-down(md) {
            padding-top: 5px !important;
            padding-bottom: 5px !important;
        }
    }
}
