@import '../../../../../Common/src/scss/components/react/LanguageSelector.scss';

.LanguageSelector {
    button {
        &.dropdown-toggle {
            border: none;
            color: $light;
        }
    }

    &.account-settings {
        button.dropdown-toggle {
            border: 2px solid $dark;

            i,
            span {
                color: $dark;
            }
        }
    }
}
