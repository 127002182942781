﻿.footer__section {
    background-color: $black;
    color: $white !important;

    & .subheader {
        color: $yellow;
        text-transform: uppercase;
        padding-bottom: 10px;
    }

    .footer__nav-row {
        @include font-size(12px);
        font-weight: 300;

        .footer__navgroup {
            border-bottom: 1px solid $yellow;
        }
    }

    .copyright-row {
        color: $white;
        @include font-size(9px);
        font-weight: 300;

        .footer__navgroup {
            .navgroup__link {
                a {
                    color: $yellow;

                    &:hover {
                        color: $white;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down('md') {
        padding-left: 15% !important;
        padding-right: 15% !important;
        padding-top: 60px;
    }

    @include media-breakpoint-down('xs') {
        padding-left: 5% !important;
        padding-right: 5% !important;
        padding-top: 60px;
    }
}

.footer__navgroup {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;

    li.navgroup__link {
        display: inline;

        &:not(:first-child)::before {
            content: '|';
        }

        a {
            color: $white;
            text-transform: uppercase;

            &:hover {
                text-decoration: none;
                color: $yellow;
            }
        }
    }
}
.footer__address {
    font-weight: normal;
    font-size: 16px;
    padding-top: 20px;
}

.footer__email {
    font-size: 16px;
    color: $yellow;
}

.footer__social i {
    margin-right: 3px;
    font-size: 36px;
}

.footer__logo {
}

.footer__bottom {
    font-size: 14px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10%;
    padding-left: 10%;
    font-family: var(--tertiary-font);
}

.footer__bottom a {
    color: $white;
    padding-left: 5px;
}

.footer__subscription {
    background-color: $gray-dark;
    padding-top: 20px;
    padding-bottom: 20px;

    & .alert-custom {
        color: $white !important;
        background-color: $gray-dark !important;
        border-color: $yellow !important;
    }

    & label {
        color: $white !important;
    }

    & button[type='submit'],
    input[type='email'] {
        border-color: $white;
    }

    & button[type='submit'] {
        padding-top: 15px;
        padding-bottom: 11px;
        padding-left: 39px;
        padding-right: 39px;
        border-color: var(--primary);
    }

    input[type='email'] {
        font-size: 1rem;
        padding: 21px 0.7em;
        width: 326px;
    }

    & input[type='email']::-webkit-input-placeholder {
        font-family: var(--tertiary-font);
        letter-spacing: 0.3px;
        color: $black;
        font-weight: normal !important;
        @include font-size(16);
    }

    & input[type='email']::-moz-placeholder {
        font-family: var(--tertiary-font);
        letter-spacing: 0.3px;
        color: $black;
        font-weight: normal !important;
        @include font-size(16);
    }

    & input[type='email']:-moz-placeholder {
        font-family: var(--tertiary-font);
        letter-spacing: 0.3px;
        color: $black;
        font-weight: normal !important;
        @include font-size(16);
    }

    & input[type='email']:-ms-input-placeholder {
        font-family: var(--tertiary-font);
        letter-spacing: 0.3px;
        color: $black;
        font-weight: normal !important;
        @include font-size(16);
    }

    @include media-breakpoint-down('sm') {
        & .subheader,
        input.form-control,
        button[type='submit'] {
            font-size: 16px;
        }

        & label {
            margin-bottom: 0.5rem;
        }

        & button[type='submit'] {
            padding-top: 9px;
            padding-bottom: 5px;
            padding-left: 28px;
            padding-right: 28px;
        }

        input[type='email'] {
            padding: 6px 0.7em;
        }

        & input[type='email']::-webkit-input-placeholder {
            @include font-size(12);
        }

        & input[type='email']::-moz-placeholder {
            @include font-size(12);
        }

        & input[type='email']:-moz-placeholder {
            @include font-size(12);
        }

        & input[type='email']:-ms-input-placeholder {
            @include font-size(12);
        }
    }
}
