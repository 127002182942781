﻿@import '../../../../Common/src/scss/sections/_header.scss';

.header {
    &__items {
        padding: 1rem 0rem;
        display: grid;
        grid-auto-columns: 33%;
        grid-auto-rows: 1fr;
        align-items: center;
    }

    &__search {
        grid-column-start: 1;
    }

    &__logo {
        grid-column-start: 2;
        min-height: 50px;
        display: flex;
        justify-content: center;
    }

    &__language {
        grid-column-start: 3;
        display: flex;
        justify-content: end;
    }
}

.header__banner {
    text-align: center !important;
    @include font-size(14);
    background: var(--primary);
    text-align: center;
}

.header__section {
    background-color: $black;
    .search {
        span {
            pointer-events: none;
        }
    }
}

.header__social {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    width: 100%;

    li {
        display: inline-block;

        a {
            color: $white;
            @include font-size(24px);

            &:hover {
                text-decoration: none;
            }
        }
    }

    .icon-tiktokicon {
        margin-left: -10px;
        margin-right: -10px;
    }
    .icon-tiktokicon:hover {
        text-decoration: none;
    }
}

.header__my-account-container {
    position: absolute;
    right: 0;
    top: 0;
    color: $white;
    height: 100%;

    button.header__minicart {
        background: transparent;
        color: var(--primary);
        padding-left: 15px;
        border: none;
    }
}

.header__usermenu {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

.header__logo {
    position: relative;
    z-index: 1;
}
