﻿@import '../../../../../Common/src/scss/components/react/ProductDescriptionTab.scss';

.ProductDescriptionTab {
    .ProductDescriptionTab__Description {
        p {
            @include font-size(14px, true);
            font-weight: 600;
        }
    }
}
