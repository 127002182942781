.prostaff__cardrow,
.prostaff__cardgrid {
    .card {
        .card-img-top {
            filter: brightness(60%);
        }

        .title {
            @include font-size(24px, true);
            font-weight: 700 !important;
            word-spacing: normal !important;
        }

        .subtitle {
            font-weight: 600;
            @include font-size(12px, true);
            text-transform: uppercase;
            letter-spacing: normal;
            padding-bottom: 2.5em;
        }
    }

    .sort {
        .dropdown__drawer {
            .dropdown-item {
                color: $white;
            }
        }
    }
}

.prostaff__cardrow {
    .card {
        .card-img {
            filter: brightness(60%);
        }

        > a {
            margin-left: 0 !important;
        }
    }
}
