﻿@import '../../../../Common/src/scss/pages/_commonnode.scss';

.catalog-node {
    ul.nav-tabs {
        > li {
            a.nav-link {
                color: $dark;
            }
        }
    }
}
